<template>
  <SharedServiceCard v-bind="props" :item="props.item">
    <template #header>
      <SharedServiceCardHeader
        :classes="{
          ...props.classes?.header,
          root: [{ 'p-3': props.variant === 'small' }, props.classes?.header?.root],
        }"
        :loading
      >
        <template #icon>
          <IconRankBurger v-if="props.item?.id === 'snp500'" :size="variant === 'small' ? 10 : 12" />
          <IconGraph v-else :size="variant === 'small' ? 10 : 12" />
        </template>

        <template #title>
          <span
            :class="
              cn(
                'font-medium leading-none',
                { 'text-xxs': variant === 'small' },
                props.classes?.header?.title,
                {}
              )
            "
            >Portfolio</span
          >
        </template>
      </SharedServiceCardHeader>
    </template>

    <!-- @ts-ignore -->
    <template #footer="slotProps">
      <slot v-bind="slotProps" name="footer" />
    </template>
  </SharedServiceCard>
</template>

<script setup lang="ts" generic="T extends ServiceCardItem">
import type {
  ServiceCardItem,
  ServiceCardProps,
} from "@finq/app-base/components/shared/ServiceCard/ServiceCard.vue"

const props = withDefaults(defineProps<ServiceCardProps>(), { size: "255px" })
</script>
