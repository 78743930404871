<template>
  <UiCard v-if="error" v-bind="cardAttributes">
    <UiNoData
      :config="tmsafe('stocks.no_data')"
      :classes="{ title: 'text-base font-semibold leading-normal', text: 'm-0' }"
    />
  </UiCard>
  <UiCard v-else v-bind="cardAttributes">
    <template #header="{ labelledBy }">
      <slot name="header" :labelledBy>
        <SharedServiceCardHeader
          :loading
          :title="item.header"
          :classes="{
            ...props.classes?.header,
            root: { 'p-3': props.variant === 'small' },
            title: [{ 'text-xxs': variant === 'small' }, props.classes?.header?.title],
          }"
        />
      </slot>
    </template>

    <template v-slot="{ labelledBy }">
      <UiTransition name="scrollY" duration="duration-1000">
        <NuxtImg
          v-if="props.item?.image"
          v-show="!loading"
          :key="props.item?.image"
          :class="cn('absolute inset-0 block h-full w-full select-none object-cover', props.classes?.image)"
          :src="getImageUrl(props.item?.image)"
          :alt="props.item?.name"
          loading="lazy"
        />
      </UiTransition>

      <!-- Card body -->
      <slot :labelledBy>
        <UiInfo
          :loading
          :title="props.item?.name"
          :subtitle="props.item?.description"
          :classes="{
            ...props.classes?.body,
            root: ['relative', props.classes?.body?.root],
            title: [
              'text-2.5xl/7 block whitespace-pre-line font-semibold text-white',
              { 'text-lg/6 ': variant === 'small' },
              props.classes?.body?.title,
            ],
            subtitle: [
              'font-medium text-white',
              { 'text-xs/4': variant === 'small' },
              props.classes?.body?.subtitle,
            ],
            titlesSkeletonLine: ['last:w-1/2', props.classes?.body?.skeleton?.line],
          }"
          :ariaLabelledBy="labelledBy"
        >
        </UiInfo>
      </slot>
    </template>

    <template v-if="hasFooterSlot && !removeFooter" #footer>
      <div class="mt-auto flex flex-col">
        <div
          :class="
            cn(
              'flex rounded-b-md bg-gradient-to-r from-black/30 to-black/20 p-4 text-white backdrop-blur-xl ltr:bg-gradient-to-l',
              {
                'p-3 text-xs font-medium': props.variant === 'small',
              }
            )
          "
        >
          <slot name="footer"></slot>
        </div>
      </div>
    </template>
  </UiCard>
</template>

<script lang="ts">
export interface ServiceCardItem {
  name: string
  header?: string
  description?: string
  image: string
  id: string
  to?: TypedRouteProps | string
}

export interface ServiceCardProps {
  loading?: boolean
  item: NonNullable<ServiceCardItem>
  removeFooter?: boolean
  size?: string
  classes?: ServiceCardClasses
  full?: boolean
  error?: boolean
  variant?: "default" | "small"
}

export interface ServiceCardClasses extends Partial<UiCardClasses> {
  image?: ClassValue
  body?: Partial<UiInfoClasses>
}
</script>
<script setup lang="ts" generic="T extends ServiceCardItem">
import type { StyleHTMLAttributes } from "vue"

import type { UiCardClasses, UiCardProps } from "@finq/ui/components/ui/Card.vue"
import type { UiInfoClasses } from "@finq/ui/components/ui/Info.vue"

const { tmsafe } = useI18nUtils()

const props = withDefaults(defineProps<ServiceCardProps>(), {
  variant: "default",
  size: "255px",
})
const slots = useSlots()

const hasFooterSlot = computed(() => !!slots.footer)

const cardAttributes = computed((): Partial<UiCardProps> & StyleHTMLAttributes => ({
  border: "none",
  style: { "--ui-card-image-size": props.size, minWidth: props.size },
  classes: {
    ...props.classes,
    root: cn(
      [
        "h-[--ui-card-image-size] w-full overflow-hidden bg-gray-100 md:h-[215px] md:max-w-none",
        props.classes?.root,
      ],
      { "max-w-[--ui-card-image-size]": !props.full },
      props.classes?.root
    ),
    body: cn(["px-4 py-0 md:px-3 md:py-0", { "px-3": props.variant === "small" }, props.classes?.body]),
  },
}))
</script>
